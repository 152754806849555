import React, { ReactNode, ReactNodeArray } from 'react'
import styles from './Container.module.scss'
import cx from 'classnames'

interface Props {
  children: ReactNode | ReactNodeArray
  style?: string
}

function Container({ children, style }: Props) {
  return <div className={cx(styles.Container, style)}>{children}</div>
}

Container.displayName = 'Container'

export default Container
