import React from 'react'
import styles from './Authors.module.scss'
import FooterPageContainer from 'src/components/FooterPageContainer'
import { uiTexts, authors } from 'src/util/questionData'
import { ReactComponent as ConceptIcon } from 'src/assets/images/concept-icon.svg'
import { ReactComponent as DesignIcon } from 'src/assets/images/design-icon.svg'
import { ReactComponent as IllustrationIcon } from 'src/assets/images/illustration-icon.svg'
import { ReactComponent as ImplementationIcon } from 'src/assets/images/implementation-icon.svg'
import { ReactComponent as InterviewIcon } from 'src/assets/images/interview-icon.svg'
import { ReactComponent as ProducersIcon } from 'src/assets/images/producers-icon.svg'

function Authors() {
  const texts = uiTexts.authors
  const {
    journalists,
    technicalImplementation,
    producers,
    design,
    illustrations,
    headOfDesign,
  } = authors

  return (
    <FooterPageContainer title={texts.title}>
      <div className={styles.Section}>
        <div className={styles.Icon}>
          <ConceptIcon />
        </div>
        <div className={styles.Content}>
          <h2 className={styles.Label}>{texts.headOfDesign}</h2>
          <span className={styles.Authors}>{headOfDesign.join(', ')}</span>
        </div>
      </div>

      <div className={styles.Section}>
        <div className={styles.Icon}>
          <DesignIcon />
        </div>
        <div className={styles.Content}>
          <h2 className={styles.Label}>{texts.design}</h2>
          <span className={styles.Authors}>{design.join(', ')}</span>
        </div>
      </div>

      <div className={styles.Section}>
        <div className={styles.Icon}>
          <ProducersIcon />
        </div>
        <div className={styles.Content}>
          <h2 className={styles.Label}>{texts.producers}</h2>
          <span className={styles.Authors}>{producers.join(', ')}</span>
        </div>
      </div>

      <div className={styles.Section}>
        <div className={styles.Icon}>
          <InterviewIcon />
        </div>
        <div className={styles.Content}>
          <h2 className={styles.Label}>{texts.journalists}</h2>
          <span className={styles.Authors}>{journalists.join(', ')}</span>
        </div>
      </div>

      <div className={styles.Section}>
        <div className={styles.Icon}>
          <ImplementationIcon />
        </div>
        <div className={styles.Content}>
          <h2 className={styles.Label}>{texts.technicalImplementation}</h2>
          <span className={styles.Authors}>
            {technicalImplementation.join(', ')}
          </span>
        </div>
      </div>

      <div className={styles.Section}>
        <div className={styles.Icon}>
          <IllustrationIcon />
        </div>
        <div className={styles.Content}>
          <h2 className={styles.Label}>{texts.illustrations}</h2>
          <span className={styles.Authors}>{illustrations.join(', ')}</span>
        </div>
      </div>
    </FooterPageContainer>
  )
}

export default Authors
