import React, { MouseEvent } from 'react'
import style from './Button.module.scss'
import cx from 'classnames'

export enum ButtonType {
  submit,
  disabledSubmit,
  cancel,
  disabledCancel,
  neutral,
  disabledNeutral,
}

interface Props {
  text: string
  type: ButtonType
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  value?: string | number | string[] | undefined
  className?: string
  icon?: string
  alt?: string
}

export default function Button({
  text,
  type,
  onClick,
  value,
  className,
  icon,
  alt,
}: Props) {
  return (
    <button
      className={cx(style.SolidButton, className, {
        [style.Neutral]: type === ButtonType.neutral,
        [style.DisabledNeutral]: type === ButtonType.disabledNeutral,
        [style.Submit]: type === ButtonType.submit,
        [style.DisabledSubmit]: type === ButtonType.disabledSubmit,
        [style.Cancel]: type === ButtonType.cancel,
        [style.DisabledCancel]: type === ButtonType.disabledCancel,
      })}
      onClick={onClick}
      value={value}
    >
      <div className={style.Content}>
        <span className={style.Text}>{text}</span>
        {icon && <img src={icon} alt={alt} className={style.Icon} />}
      </div>
    </button>
  )
}
