import { createContext } from 'react'
import browserRoutes from './browser'

export interface NavigationRoute {
  name: string
  params?: { [_: string]: any }
}

interface NavigationState {
  current: NavigationRoute
  routes: ReadonlyArray<NavigationRoute>
  pop: (params?: any) => void
  push: (route: NavigationRoute) => void
}

const NavigationContext = createContext<NavigationState>({
  current: {
    name: browserRoutes.start,
  },
  routes: [],
  pop: () => {},
  push: () => {},
})

export default NavigationContext
