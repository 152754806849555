import React, { useContext } from 'react'
import styles from './Avatar.module.scss'
import { ReactComponent as ThumbUp } from 'src/assets/images/thumb-up.svg'
import { ReactComponent as ThumbDown } from 'src/assets/images/thumb-down.svg'
import { Expert } from 'src/util/questionData'
import NavigationContext from 'src/routes/NavigationContext'
import browserRoutes from 'src/routes/browser'

interface Props {
  expert: Expert
  hasAnsweredYes: boolean
  questionNumber: number
}

function Avatar({ questionNumber, expert, hasAnsweredYes }: Props) {
  const ThumbIcon = hasAnsweredYes ? ThumbUp : ThumbDown
  const { push } = useContext(NavigationContext)

  return (
    <button
      title={expert?.name}
      className={styles.Button}
      onClick={() =>
        push({
          name: browserRoutes.expertAnswer,
          params: {
            questionNumber,
            expert: expert?.name ?? '',
          },
        })
      }
    >
      <div
        className={styles.Avatar}
        style={
          expert?.image
            ? {
                backgroundImage: `url(${expert?.image})`,
              }
            : undefined
        }
      >
        <ThumbIcon className={styles.Thumb} />
      </div>
    </button>
  )
}

export default Avatar
