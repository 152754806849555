import React, { CSSProperties } from 'react'
import styles from './Bar.module.scss'
interface Props {
  label: string
  percentage: number
}

const overflowBreakpoint = 20

export default function Bar({ percentage, label }: Props) {
  const remaining = 100 - percentage
  const stops = [
    `#3e92de 0`,
    `#3e92de ${percentage}%`,
    `#FFF ${percentage}%`,
    `#FFF calc(${percentage}% + 2px)`,
    `#e92e2e calc(${percentage}% + 2px)`,
    `#e92e2e 100%`,
  ]
  const colors = stops.reduce((a, v) => `${a}, ${v}`)
  const style: CSSProperties = {}
  if (percentage > 0) {
    style.background = `linear-gradient(90deg, ${colors})`
  } else if (percentage === 100) {
    style.backgroundColor = '#3e92de'
  } else if (percentage === 0) {
    style.backgroundColor = '#e92e2e'
  }

  return (
    <div>
      <h3 className={styles.Label}>{label}</h3>
      <div className={styles.BarContainer}>
        <div className={styles.OverflowLeft}>
          <p className={styles.Percentage}>
            {percentage <= overflowBreakpoint && `${percentage}%`}
          </p>
        </div>
        <div className={styles.Bar} style={style}>
          {percentage > 0 && (
            <span
              className={styles.ApproveSection}
              style={{ width: `calc(${percentage}% - 2px)` }}
            >
              <p className={styles.Percentage}>
                {percentage > overflowBreakpoint && `${percentage}%`}
              </p>
            </span>
          )}
          {remaining > 0 && (
            <span
              className={styles.DisapproveSection}
              style={{ width: `calc(${remaining}% - 2px)` }}
            >
              <p className={styles.Percentage}>
                {remaining > overflowBreakpoint && `${remaining}%`}
              </p>
            </span>
          )}
        </div>
        <div className={styles.OverflowRight}>
          <p className={styles.Percentage}>
            {remaining <= overflowBreakpoint && `${remaining}%`}
          </p>
        </div>
      </div>
    </div>
  )
}
