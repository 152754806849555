import React, { useEffect } from 'react'
import Container from '../Container'
import resultBackground from 'src/assets/images/Olet_valmis.jpg'
import styles from './Results.module.scss'
import { questions, population, uiTexts } from 'src/util/questionData'
import { useContext } from 'react'
import AnswersContext from 'src/util/AnswersContext'
import navButton from 'src/assets/images/nav-button.svg'
import NavigationContext from 'src/routes/NavigationContext'
import ResultBar from 'src/components/ResultBar'
import { useLayoutEffect } from 'react'
import Button, { ButtonType } from 'src/components/Button/Button'

export default function Results() {
  const { answers } = useContext(AnswersContext)
  const {
    title,
    subtitle,
    populationTitle,
    youAnswered,
    start,
  } = uiTexts.results
  const { yesButton, noButton } = uiTexts.common
  const { pop } = useContext(NavigationContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useLayoutEffect(() => {
    document
      .getElementById('result-highlight')
      ?.classList.add(styles.Hightlight)
  }, [])

  return (
    <>
      <Container>
        <div className={styles.HeroContainer}>
          <button
            className={styles.Button}
            onClick={() => pop({ keepScrollPosition: false })}
          >
            <img
              src={navButton}
              className={styles.TopNavButton}
              alt="Nuoli vasemmalle"
            />
          </button>
          <img
            src={resultBackground}
            alt="Vuorenvalloittaja"
            className={styles.HeroIllustration}
          />
          <div className={styles.TopNavButton}></div>
        </div>

        <h1 className={styles.Title}>{title}</h1>
        <p
          className={styles.Subtitle}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        ></p>
        {questions.map((q) => {
          const populationAnswer = population.find(
            (a) => a.questionNumber === q.questionNumber
          )
          const userAnswer = answers[`${q.questionNumber}`] || 'no'
          const { yes: approveSummaries = [], no: disapproveSummaries = [] } =
            populationAnswer?.reasonings || {}
          const userAnsweredNo = userAnswer === 'no'

          return (
            <div key={q.questionNumber}>
              <h2
                className={styles.Question}
              >{`${q.questionNumber}. ${q.abridgment}`}</h2>
              <p className={styles.Answer}>
                {`${youAnswered} `}
                <span
                  className={
                    userAnsweredNo ? styles.NoAnswer : styles.YesAnswer
                  }
                >
                  {userAnsweredNo ? noButton : yesButton}
                </span>
              </p>
              <p className={styles.Answer}>{populationTitle}</p>
              <ResultBar
                percentage={populationAnswer?.answers.allDemographics ?? 0}
                approveSummaries={approveSummaries}
                disapproveSummaries={disapproveSummaries}
                answer={userAnswer || 'no'}
              />
            </div>
          )
        })}
        <Button
          onClick={() => window?.location.reload()}
          text={start}
          type={ButtonType.neutral}
          className={styles.StartButton}
        />
      </Container>
    </>
  )
}
