import React from 'react'
import styles from './FooterPageContainer.module.scss'
import Container from 'src/pages/Container'
import { ReactComponent as ArrowBack } from 'src/assets/images/arrow-back.svg'
import { useContext } from 'react'
import NavigationContext from 'src/routes/NavigationContext'
import { useEffect } from 'react'

interface Props {
  children: React.ReactNode | React.ReactNodeArray
  title: string
}

function Faq({ children, title }: Props) {
  const { pop } = useContext(NavigationContext)

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Container style={styles.Container}>
      <button className={styles.Button} onClick={pop}>
        <ArrowBack />
      </button>
      <h1 className={styles.Title}>{title}</h1>
      {children}
    </Container>
  )
}

export default Faq
