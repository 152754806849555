import React from 'react'
import styles from './Footer.module.scss'
import Container from 'src/pages/Container'
import { uiTexts } from 'src/util/questionData'
import { useContext } from 'react'
import NavigationContext from 'src/routes/NavigationContext'
import browserRoutes from 'src/routes/browser'

function Footer() {
  const { faq, feedback, authors, feedbackUrl } = uiTexts.common
  const { push } = useContext(NavigationContext)

  return (
    <footer className={styles.Footer}>
      <Container style={styles.Container}>
        <a
          href={feedbackUrl}
          className={styles.FirstLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {feedback}
        </a>
        <button
          className={styles.Link}
          onClick={() =>
            push({
              name: browserRoutes.faq,
            })
          }
        >
          {faq}
        </button>
        <button
          className={styles.LastLink}
          onClick={() =>
            push({
              name: browserRoutes.authors,
            })
          }
        >
          {authors}
        </button>
      </Container>
    </footer>
  )
}

export default Footer
