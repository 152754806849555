import React from 'react'
import cx from 'classnames'
import styles from './ResultBar.module.scss'

interface Props {
  percentage: number
  onButtonClick: () => void
  style: string
}

const overflowBreakpoint = 20

function OverflowContent({ percentage, onButtonClick, style }: Props) {
  if (percentage > overflowBreakpoint) {
    return (
      <div className={cx(styles.Button, style)} onClick={onButtonClick}>
        <p className={styles.Percentage}></p>
      </div>
    )
  }

  return (
    <button className={cx(styles.Button, style)} onClick={onButtonClick}>
      <p className={styles.Percentage}>{`${percentage}%`}</p>
    </button>
  )
}

export default OverflowContent
