import React, { useLayoutEffect } from 'react'
import styles from './Statement.module.scss'
import { questions } from 'src/util/questionData'
import DefinitionPopup from '../DefinitionPopup'

interface Props {
  questionNumber: number
  onOpenDefinition: () => void
  onCloseDefinition: () => void
  definitionVisible: boolean
}

function Statement({
  questionNumber,
  onOpenDefinition,
  onCloseDefinition,
  definitionVisible,
}: Props) {
  const { definition, question } = questions.find(
    (q) => q.questionNumber === questionNumber
  )!

  useLayoutEffect(() => {
    if (definition) {
      const token = document.getElementById(definition.token)
      if (token) {
        token.onclick = onOpenDefinition
        token.classList.add(styles.Token)
      }
    }

    return () => {
      if (definition) {
        const token = document.getElementById(definition.token)
        if (token) {
          token.onclick = null
        }
      }
    }
  }, [definition, onOpenDefinition])

  return (
    <>
      <h1
        className={styles.Statement}
        dangerouslySetInnerHTML={{ __html: question }}
      ></h1>
      {definitionVisible && (
        <DefinitionPopup
          text={definition?.text ?? ''}
          source={definition?.source ?? ''}
          url={definition?.url ?? ''}
          onClose={onCloseDefinition}
        />
      )}
    </>
  )
}

export default Statement
