import { useState } from 'react'
import { isEmpty } from 'lodash-es'

export enum StorageType {
  BackgroundInformation = 'BackgroundInformation',
  Answers = 'Answers',
}

export default function useSessionStorage<T>(
  key: StorageType
): [T, typeof setValue] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.sessionStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return (item ? JSON.parse(item) : {}) as T
    } catch (error) {
      console.log(error)
      return {} as T
    }
  })

  // Return a wrapped version of useState's setter function
  const setValue = (valueToStore: T) => {
    try {
      setStoredValue(valueToStore)
      if (!isEmpty(valueToStore)) {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
      } else {
        window.sessionStorage.removeItem(key)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue]
}
