import React from 'react'
import {
  PopulationData,
  uiTexts,
  segments as segmentNames,
} from 'src/util/questionData'
import Bar from '../Bar/Bar'
import styles from './DemographyBars.module.scss'

interface Props {
  answers: PopulationData['answers']
}

export const SCROLL_TARGET_ID = 'other-answers'

export default function DemographyBars({ answers }: Props) {
  const answersToShow = Object.entries(answers)
  const { title } = uiTexts.answer

  return (
    <section>
      <h2 className={styles.Title} id={SCROLL_TARGET_ID}>
        {title}
      </h2>
      <div className={styles.Answers}>
        {answersToShow.map(([key, value]) => (
          <Bar
            label={(segmentNames as { [_: string]: string })[key]}
            percentage={value ?? 0}
            key={key}
          />
        ))}
      </div>
    </section>
  )
}
