import React from 'react'
import browserRoutes from 'src/routes/browser'
import Start from 'src/pages/Start'
import Question from 'src/pages/Question/Question'
import {
  questions,
  expertAnswers,
  population,
  ExpertData,
  PopulationData,
} from 'src/util/questionData'
import { NavigationRoute } from 'src/routes/NavigationContext'
import Results from 'src/pages/Results/Results'
import Faq from 'src/pages/Faq'
import Authors from 'src/pages/Authors'
import ExpertAnswer from 'src/pages/ExpertAnswer'

interface Props {
  route: NavigationRoute
}

function Switch({ route }: Props) {
  const question = getQuestion(route.params?.questionNumber)

  switch (route.name) {
    case browserRoutes.start:
      return <Start />
    case browserRoutes.question:
      if (question) {
        return (
          <Question
            {...question}
            lastQuestionNumber={questions.length}
            expertAnswers={getExpertAnswers(question.questionNumber)}
            populationAnswers={getPopulationAnswers(question.questionNumber)}
            image={require(`../../assets/images/${question.illustration}`)}
            imageAlt={question.alt}
          />
        )
      }
      return <Start />
    case browserRoutes.expertAnswer:
      return <ExpertAnswer questionNumber={route.params?.questionNumber} />
    case browserRoutes.end:
      return <Results />
    case browserRoutes.faq:
      return <Faq />
    case browserRoutes.authors:
      return <Authors />
    default:
      return <Start />
  }
}

function getQuestion(questionNumber: number) {
  return questions.find((q) => q.questionNumber === questionNumber)
}

function getExpertAnswers(questionNumber: number): ExpertData['answers'] {
  return expertAnswers.find((e) => e.questionNumber === questionNumber)
    ?.answers as ExpertData['answers']
}

function getPopulationAnswers(questionNumber: number): PopulationData {
  return population.find(
    (e) => e.questionNumber === questionNumber
  ) as PopulationData
}

export default Switch
