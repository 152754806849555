const browserRoutes = {
  start: '/',
  question: '/kysymys',
  expertAnswer: '/asiantuntijan-vastaus',
  end: '/tulokset',
  faq: '/ukk',
  authors: '/tekijat',
}

export default browserRoutes
