import React from 'react'
import styles from './DefinitionPopup.module.scss'
import { uiTexts } from 'src/util/questionData'
import { useRef } from 'react'
import useClickOutside from 'src/util/useClickOutside'

interface Props {
  text: string
  url?: string
  source?: string
  onClose: () => void
}

function DefinitionPopup({ text, url = '', source = '', onClose }: Props) {
  const { closeTitle, sourceTitle } = uiTexts.question
  const popup = useRef<HTMLDivElement>(null)

  useClickOutside(popup, () => onClose())

  return (
    <div className={styles.Container} ref={popup}>
      <p className={styles.Text}>{text}</p>
      {url?.length > 0 && source?.length > 0 && (
        <a
          href={url}
          className={styles.Link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${sourceTitle}: ${source}`}
        </a>
      )}
      <button onClick={onClose} className={styles.CloseButton}>
        {closeTitle}
      </button>
    </div>
  )
}

export default DefinitionPopup
