import React from 'react'
import styles from './Expert.module.scss'
import avatarPlaceholder from 'src/assets/images/avatar-placeholder.png'
import { uiTexts } from 'src/util/questionData'

export interface ExpertProps {
  image: string
  name: string
  profession: string
  question: string
  answer: 'yes' | 'no'
  reasoning: string
  timestamp: string
}

function Expert({
  image,
  name,
  profession,
  question,
  answer,
  reasoning,
  timestamp,
}: ExpertProps) {
  const hasAnsweredYes = answer === 'yes'
  const { answered, expertAnswered } = uiTexts.answer
  const { yesButton, noButton } = uiTexts.common

  return (
    <div className={styles.Professional}>
      <img
        src={require('src/assets/images/' + image) || avatarPlaceholder}
        alt={name}
        className={styles.Avatar}
      />
      <h2 className={styles.Name}>{name}</h2>
      <p className={styles.Profession}>{profession}</p>
      <h3
        className={styles.Question}
        dangerouslySetInnerHTML={{ __html: question }}
      ></h3>
      <div className={styles.AnswerContainer}>
        <p className={styles.Answer}>
          {`${expertAnswered} `}
          <span className={hasAnsweredYes ? styles.YesAnswer : styles.NoAnswer}>
            {hasAnsweredYes ? yesButton : noButton}
          </span>
        </p>
      </div>
      <p className={styles.Reasoning}>"{reasoning}"</p>
      <p className={styles.Timestamp}>
        <i>{`${answered} ${timestamp}.`}</i>
      </p>
    </div>
  )
}

export default Expert
