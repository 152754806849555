import React from 'react'
import styles from './QuestionProgress.module.scss'
import { ReactComponent as ActiveDot } from 'src/assets/images/dot-active.svg'
import { ReactComponent as InactiveDot } from 'src/assets/images/dot-inactive.svg'
interface Props {
  questionNumber: number
  lastQuestionNumber: number
}

export default function QuestionProgress(props: Props) {
  let circles = []
  for (let ix = 0; ix < props.lastQuestionNumber; ix++) {
    if (ix === props.questionNumber - 1) {
      circles.push(<ActiveDot key={`dot${ix}`} />)
    } else {
      circles.push(<InactiveDot key={`dot${ix}`} />)
    }
  }

  return <div className={styles.DotContainer}>{circles}</div>
}
