import React from 'react'
import { ExpertData, experts } from 'src/util/questionData'
import { groupBy, orderBy } from 'lodash-es'
import styles from './ExpertGrid.module.scss'
import Avatar from '../Avatar'

interface Props {
  answers: ExpertData['answers']
  questionNumber: number
}

function ExpertGrid({ answers, questionNumber }: Props) {
  const { yes: yesAnswers = [], no: noAnswers = [] } = groupBy(
    answers,
    'answer'
  )
  const yesAnswersOrderedAlphabetically = orderBy(yesAnswers, 'expertName')
  const noAnswersOrderedAlphabetically = orderBy(noAnswers, 'expertName')

  return (
    <div className={styles.Container}>
      <div className={styles.Avatars}>
        {yesAnswersOrderedAlphabetically.map((answer) => {
          const expert = experts.find((e) => e.name === answer.expertName)

          return (
            <Avatar
              key={expert?.name}
              expert={{
                ...expert!,
                image: require('src/assets/images/' + expert?.image),
              }}
              hasAnsweredYes
              questionNumber={questionNumber}
            />
          )
        })}
      </div>
      <div className={styles.Avatars}>
        {noAnswersOrderedAlphabetically.map((answer) => {
          const expert = experts.find((e) => e.name === answer.expertName)

          return (
            <Avatar
              key={expert?.name}
              expert={{
                ...expert!,
                image: require('src/assets/images/' + expert?.image),
              }}
              hasAnsweredYes={false}
              questionNumber={questionNumber}
            />
          )
        })}
      </div>
    </div>
  )
}

ExpertGrid.displayName = 'ExpertGrid'

export default ExpertGrid
