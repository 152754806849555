import React from 'react'
import styles from './Faq.module.scss'
import { faq, uiTexts } from 'src/util/questionData'
import { useEffect } from 'react'
import FooterPageContainer from 'src/components/FooterPageContainer'

function Faq() {
  const { title, subtitle, link } = uiTexts.faq
  const { linkUrl } = uiTexts.front

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <FooterPageContainer title={title}>
      <p className={styles.Ingress}>{subtitle}</p>
      {faq.map(({ question, answer }) => (
        <React.Fragment key={question}>
          <h2 className={styles.QuestionTitle}>{question}</h2>
          <p className={styles.Answer}>{answer}</p>
        </React.Fragment>
      ))}
      <a
        href={linkUrl}
        className={styles.Link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {link}
      </a>
    </FooterPageContainer>
  )
}

export default Faq
