import React from 'react'
import cx from 'classnames'
import styles from './ResultBar.module.scss'

interface Props {
  percentage: number
  onButtonClick: () => void
  style: string
}

const overflowBreakpoint = 20

function SectionContent({ percentage, onButtonClick, style }: Props) {
  if (percentage > overflowBreakpoint) {
    return (
      <button
        onClick={onButtonClick}
        className={cx(styles.Button, style)}
        style={{ width: `calc(${percentage}% - 2px)` }}
      >
        <p className={styles.Percentage}>{`${percentage}%`}</p>
      </button>
    )
  }

  return (
    <button
      onClick={onButtonClick}
      className={cx(styles.Button, style)}
      style={{ width: `calc(${percentage}% - 2px)` }}
    >
      <p className={styles.Percentage}>&nbsp;</p>
    </button>
  )
}

export default SectionContent
