import { useEffect } from 'react'

function useClickOutside(
  ref: React.RefObject<HTMLElement>,
  callback: () => void
) {
  function handleClick(e: any) {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export default useClickOutside
