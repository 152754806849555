import React, { CSSProperties } from 'react'
import styles from './ResultBar.module.scss'
import { uiTexts } from 'src/util/questionData'
import redArrow from 'src/assets/images/red-arrow.svg'
import blueArrow from 'src/assets/images/blue-arrow.svg'
import { useState } from 'react'
import OverflowContent from './OverflowContent'
import SectionContent from './SectionContent'
import cx from 'classnames'

interface Props {
  percentage: number
  approveSummaries: ReadonlyArray<string>
  disapproveSummaries: ReadonlyArray<string>
  answer: 'yes' | 'no'
}

enum SummaryType {
  'Approve' = 'Approve',
  'Disapprove' = 'Disapprove',
}

const overflowBreakpoint = 20

function ResultBar({
  percentage,
  answer,
  approveSummaries,
  disapproveSummaries,
}: Props) {
  const { yesButton, noButton } = uiTexts.common
  const {
    agreeOpinionTitle,
    disagreeOpinionTitle,
    noExpertOpinions,
  } = uiTexts.results

  const [summaryType, setSummaryType] = useState<SummaryType>(
    answer === 'no' ? SummaryType.Approve : SummaryType.Disapprove
  )
  const summariesToShow =
    summaryType === SummaryType.Approve ? approveSummaries : disapproveSummaries

  const remaining = 100 - percentage
  const stops = [
    `#3e92de 0`,
    `#3e92de ${percentage}%`,
    `#FFF ${percentage}%`,
    `#FFF calc(${percentage}% + 2px)`,
    `#e92e2e calc(${percentage}% + 2px)`,
    `#e92e2e 100%`,
  ]
  const colors = stops.reduce((a, v) => `${a}, ${v}`)
  const style: CSSProperties = {}
  if (percentage > 0) {
    style.background = `linear-gradient(90deg, ${colors})`
  } else if (percentage === 100) {
    style.backgroundColor = '#3e92de'
  } else if (percentage === 0) {
    style.backgroundColor = '#e92e2e'
  }

  const showApproveOverflow = percentage <= overflowBreakpoint
  const showDisapproveOverflow = remaining <= overflowBreakpoint

  const yesLabelWidthWithoutOverflow = `calc(((100% - 45px * 2) * (${percentage} / 100))`
  const yesLabelWidthWithOverflow = `calc(45px + ((100% - 45px * 2) * (${percentage} / 100))`
  const noLabelWidthWithoutOverflow = `calc((100% - 45px * 2) * (${remaining} / 100))`
  const noLabelWidthWithOverflow = `calc(45px + ((100% - 45px * 2) * (${remaining} / 100)))`

  return (
    <div className={styles.Container}>
      <div className={styles.BarContainer}>
        {!showApproveOverflow && <div className={styles.OverflowLeft}></div>}
        <span
          style={{
            width: !showApproveOverflow
              ? yesLabelWidthWithoutOverflow
              : yesLabelWidthWithOverflow,
          }}
        >
          <h3 className={styles.Label}>{yesButton}</h3>
        </span>
        <span
          style={{
            width: !showDisapproveOverflow
              ? noLabelWidthWithoutOverflow
              : noLabelWidthWithOverflow,
            paddingLeft: `2px`,
          }}
        >
          <h3 className={styles.Label}>{noButton}</h3>
        </span>
      </div>

      <div className={styles.BarContainer}>
        <OverflowContent
          onButtonClick={() => setSummaryType(SummaryType.Approve)}
          style={styles.OverflowLeft}
          percentage={percentage}
        />
        <div className={styles.Bar} style={style}>
          {percentage > 0 && (
            <SectionContent
              onButtonClick={() => setSummaryType(SummaryType.Approve)}
              percentage={percentage}
              style={styles.ApproveSection}
            />
          )}
          {remaining > 0 && (
            <SectionContent
              onButtonClick={() => setSummaryType(SummaryType.Disapprove)}
              percentage={remaining}
              style={styles.DisapproveSection}
            />
          )}
        </div>
        <OverflowContent
          onButtonClick={() => setSummaryType(SummaryType.Disapprove)}
          style={styles.OverflowRight}
          percentage={remaining}
        />
      </div>

      <div className={styles.ArrowContainer}>
        <div
          className={cx(
            styles.Arrow,
            summaryType === SummaryType.Disapprove ? styles.HiddenArrow : ''
          )}
        >
          <img src={blueArrow} alt="Sininen nuoli" />
        </div>
        <p
          className={styles.SummaryTitle}
          dangerouslySetInnerHTML={{
            __html:
              answer === 'no'
                ? summaryType === SummaryType.Approve
                  ? disagreeOpinionTitle
                  : agreeOpinionTitle
                : summaryType === SummaryType.Approve
                ? agreeOpinionTitle
                : disagreeOpinionTitle,
          }}
        ></p>
        <div
          className={cx(
            styles.Arrow,
            summaryType === SummaryType.Approve ? styles.HiddenArrow : ''
          )}
        >
          <img src={redArrow} alt="Punainen nuoli" />
        </div>
      </div>

      {summariesToShow.length === 0 && (
        <p className={styles.NoSummaries}>{noExpertOpinions}</p>
      )}

      {summariesToShow.map((summary) => (
        <p
          key={summary}
          className={
            summaryType === SummaryType.Approve
              ? styles.DisapproveSummary
              : styles.ApproveSummary
          }
        >
          {summary}
        </p>
      ))}
    </div>
  )
}

export default ResultBar
