import React, { useRef, useContext } from 'react'
import Container from 'src/pages/Container'
import { ReactComponent as ArrowBack } from 'src/assets/images/arrow-back.svg'
import Expert, { ExpertProps } from 'src/components/Expert/Expert'
import styles from './ExpertAnswer.module.scss'
import {
  expertAnswers,
  experts,
  questions,
  uiTexts,
} from 'src/util/questionData'
import { useLayoutEffect } from 'react'
import NavigationContext from 'src/routes/NavigationContext'
import { orderBy } from 'lodash-es'

interface Props {
  questionNumber: number
}

function ExpertAnswer({ questionNumber }: Props) {
  const { pop, current } = useContext(NavigationContext)
  const { expert } = current.params ?? {}

  const answersForQuestion =
    expertAnswers.find((e) => e.questionNumber === questionNumber)?.answers ||
    []
  const question =
    questions.find((q) => q.questionNumber === questionNumber)?.question ?? ''

  const expertsToShow: ReadonlyArray<ExpertProps> = answersForQuestion.map(
    (answer) => {
      const e = experts.find((e) => e.name === answer.expertName)

      return {
        name: e?.name ?? '',
        answer: answer.answer as ExpertProps['answer'],
        question,
        image: e?.image ?? '',
        profession: e?.profession ?? '',
        reasoning: answer.reasoning,
        timestamp: e?.answered ?? '',
      }
    }
  )
  const expertsOrderedAlphabetically = orderBy(expertsToShow, 'name')

  useLayoutEffect(() => {
    const indexOfRef = expertsOrderedAlphabetically.findIndex(
      (p) => p.name === expert
    )

    let timeout: NodeJS.Timeout
    if (refs.current) {
      timeout = setTimeout(() => {
        const scrollYTarget =
          refs.current[indexOfRef]?.getBoundingClientRect().top +
          window.pageYOffset -
          208
        window.scrollTo(0, scrollYTarget)
      }, 200)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [expert, expertsOrderedAlphabetically])

  const refs = useRef<any>([])
  refs.current = refs.current.slice(0, expertsToShow.length)

  const { backTitle } = uiTexts.question

  return (
    <Container style={styles.Container}>
      <button
        className={styles.BackArrow}
        onClick={() => pop({ keepScrollPosition: true })}
      >
        <ArrowBack className={styles.ArrowIcon} />
        <h2 className={styles.NavTitle}>{backTitle}</h2>
        <div className={styles.NavLeft}></div>
      </button>
      {expertsOrderedAlphabetically.map((e, i) => (
        <section key={e.name} ref={(el) => (refs.current[i] = el)}>
          <Expert {...e} />
        </section>
      ))}
    </Container>
  )
}

export default ExpertAnswer
